import React from 'react';

export const Wallet = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='27'
    height='24'
    viewBox='0 0 27 24'
    className='wallet-icon body'
  >
    <defs></defs>
    <g transform='translate(11028 -19030)'>
      <path
        fill='#fff'
        d='M24.75-21H2.25A2.251,2.251,0,0,0,0-18.75v13.5A2.251,2.251,0,0,0,2.25-3h22.5A2.251,2.251,0,0,0,27-5.25v-13.5A2.251,2.251,0,0,0,24.75-21ZM22.5,1.875A1.122,1.122,0,0,0,21.375.75H16.753l-.872-2.616a.56.56,0,0,0-.534-.384H11.658a.56.56,0,0,0-.534.384L10.252.75H5.625a1.125,1.125,0,0,0,0,2.25h15.75A1.122,1.122,0,0,0,22.5,1.875Z'
        transform='translate(-11028 19051)'
      ></path>
      <g transform='translate(-11021 19034) scale(0.4)'>
        <path d='M17.8956 12.0018L32.0458 4.52327L29.6668 0L16.7098 5.18441C16.3761 5.31809 16.0062 5.31809 15.6726 5.18441L2.71192 0L0.333008 4.52327L14.4832 12.0018L0.333008 19.4767L2.71192 24L15.669 18.8156C16.0027 18.6819 16.3725 18.6819 16.7061 18.8156L29.6632 24L32.0421 19.4767L17.8919 11.9982L17.8956 12.0018Z'></path>
      </g>
    </g>
  </svg>
);
